<div class="container py-2" id="about">
    <div class="h-100 p-5 bg-color text-white border rounded-3 text-center shadow">
        <img class="my-0 mr-md-auto logo logo-img" src="/assets/images/logo/derenda-logo-icon.svg"
             alt="DerendaApp Logo Icon" title="DerendaApp Logo Icon">
        <h1 class="mx-2 mx-md-0 display-6 fw-bold">Witamy</h1>
        <p>Firma została założona przez Andrzeja Derenda w 1995 roku. Specjalizujemy się w kompleksowych usługach
            związanych z instalacjami. Jesteśmy zespołem doświadczonych fachowców, którzy od lat zajmują się
            projektowaniem, montażem oraz serwisowaniem instalacji wodno - kanalizacyjnych w budynkach mieszkalnych,
            komercyjnych oraz przemysłowych. Nasza oferta obejmuje zarówno instalacje wodociągowe, jak i kanalizacyjne,
            a także systemy centralnego ogrzewania i rekuperacji, dostosowane do indywidualnych potrzeb klienta. Dzięki
            wysokiej jakości materiałom, nowoczesnym technologiom i precyzyjnej realizacji, zapewniamy niezawodne oraz
            energooszczędne rozwiązania, które służą naszym klientom przez długie lata. Każdy projekt traktujemy
            indywidualnie, dbając o pełne zadowolenie z wykonanej usługi. Oferujemy również szybki i fachowy serwis
            awaryjny, gwarantując pełną pomoc w przypadku nagłych problemów z instalacjami.</p>
    </div>
</div>
