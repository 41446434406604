<app-navbar></app-navbar>
<div class="wrapper">
    <div class="container py-2">
        <div class="text-center pb-3">
            <h1>App Regulations</h1>
        </div>
        <h5>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum est excepturi expedita ipsam officia
            ratione repellat sed tempore! Amet atque doloremque doloribus est nisi repellat veritatis voluptas.
            Aspernatur assumenda consequuntur deleniti dolorum fuga natus, quaerat quos recusandae sint tenetur.
            Assumenda atque distinctio est fuga labore molestiae perferendis possimus sed veritatis.</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium aliquid dolor ducimus iusto maxime
            nisi nostrum ratione repudiandae voluptatibus.</p>
        <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis, provident.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam ducimus eos, quaerat quidem
                soluta.
            </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad adipisci asperiores dignissimos doloribus,
                eos et eum ex expedita fuga impedit iusto nam nobis nostrum placeat quibusdam recusandae reiciendis rem
                veniam.
            </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem quo sed sequi!</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque pariatur, qui. Ad adipisci atque
                consectetur cumque eius fugit illo inventore modi nulla, veniam! Accusantium aliquam autem corporis
                distinctio eaque esse eveniet exercitationem magnam, maxime molestiae, optio quis quisquam ratione
                reprehenderit, similique ut vero. Alias, ipsa.
            </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad blanditiis dolore, esse in incidunt ipsa
                laboriosam non nulla, pariatur quis, rem sit ut!
            </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus blanditiis eaque quasi.</li>
        </ul>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus adipisci beatae consectetur deleniti
            dolores error fugit id inventore nobis numquam officiis possimus provident quae quaerat quis rem sint
            tempore unde veritatis, voluptatem. Corporis necessitatibus quod quos suscipit tempora. Accusamus
            accusantium aperiam at blanditiis, commodi ducimus, eaque facere fugiat incidunt mollitia nostrum rerum
            soluta voluptatum? Dolore, totam?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum officiis provident ut voluptatibus.
            Blanditiis dolores incidunt labore pariatur repellat? Adipisci beatae consequatur cum eligendi ex illo
            itaque nam nulla quisquam suscipit. Accusamus beatae dolor dolorum ducimus laboriosam molestias nam
            necessitatibus, nemo optio perferendis vero voluptate. A ad animi aspernatur autem blanditiis cumque
            debitis, eaque enim fuga harum inventore itaque modi nemo odit perspiciatis quae recusandae sequi sint
            voluptatem voluptatibus. Assumenda ipsam numquam pariatur provident. Architecto consequuntur ducimus hic
            iure magni, quibusdam? Exercitationem nulla optio quod. Ad dolorum eligendi quo? Aliquid atque consequuntur
            dolore est iusto laudantium nemo quam quasi quos voluptates. Aut, dolorum ea eius esse excepturi, hic ipsam
            iure laboriosam laborum nemo officia quasi quidem reiciendis sunt temporibus unde vero. Aut, veritatis.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad atque aut, commodi consequatur corporis debitis
            deleniti deserunt distinctio dolore dolorem doloribus dolorum esse et excepturi, explicabo fugit hic illum
            in iure labore modi nesciunt nisi nobis nostrum odio officiis pariatur placeat quia quidem quis quo
            reiciendis repellendus similique tempora totam ullam voluptatum. Eum nihil sequi soluta unde voluptas?
            Dolores eos exercitationem neque provident repellat sint ullam voluptatibus. Autem similique, vel.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A alias, beatae dicta doloremque doloribus est ex
            expedita facilis harum impedit iste itaque obcaecati porro quae quis quisquam rem reprehenderit sunt ullam
            veniam? Ab aliquam beatae deleniti ipsum modi perferendis quasi ratione vero! Asperiores aspernatur atque
            delectus deserunt excepturi id magni recusandae vero voluptas voluptatem! Accusantium architecto corporis
            magnam neque odio omnis quia quidem reiciendis repudiandae veritatis!</p>
        <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis, provident.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam ducimus eos, quaerat quidem
                soluta.
            </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad blanditiis dolore, esse in incidunt ipsa
                laboriosam non nulla, pariatur quis, rem sit ut!
            </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus blanditiis eaque quasi.</li>
        </ul>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad atque beatae corporis dolorem, dolorum, hic iure
            libero natus pariatur sapiente unde voluptate.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusamus asperiores, at deserunt dolorem eius
            expedita harum inventore ipsa nemo nulla perspiciatis possimus suscipit? A architecto beatae blanditiis
            delectus dolore enim ex expedita explicabo, facilis labore laudantium minima nam officia officiis pariatur
            provident quaerat ratione reprehenderit saepe totam. A aperiam assumenda at atque cumque delectus deleniti
            deserunt dolorem eos expedita explicabo necessitatibus non obcaecati odit, pariatur, porro quibusdam quis
            recusandae rem repellat? Amet aperiam, assumenda atque cupiditate deleniti dolorum, ducimus error excepturi
            exercitationem, facere labore neque nisi optio quam quas quibusdam quisquam quod reprehenderit repudiandae
            veritatis vitae voluptas voluptatum?</p>
    </div>
    <app-footer></app-footer>
</div>
