import {Component} from '@angular/core';

@Component({
    selector: 'app-regulations',
    templateUrl: './regulations.component.html',
    styleUrls: ['./regulations.component.scss', './../public.component.scss']
})
export class RegulationsComponent {

}
